export const spriteList = [
  {
    id: 0,
    name: 'Burst',
    src: '../../images/sprites/comp.webp'
  }, {
    id: 1,
    name: 'Barrier',
    src: '../../images/sprites/barrier.webp'
  }, {
    id: 2,
    name: 'Imbue', //spell
    src: '../../images/sprites/comp.webp'
  }, {
    id: 3,
    name: 'Scout',
    src: '../../images/sprites/scout.webp'
  }, {
    id: 4,
    name: 'Augment',
    src: '../../images/sprites/augment.webp'
  }, {
    id: 5,
    name: 'Attach',
    src: '../../images/sprites/attach.webp'
  }, {
    id: 6,
    name: 'ChampLevelUpIcon3', // levelUp_2
    src: '../../images/sprites/comp.webp'
  }, {
    id: 7,
    name: 'Aura',
    src: '../../images/sprites/aura.webp'
  }, {
    id: 8,
    name: 'GenericTrigger', // effect
    src: '../../images/sprites/comp.webp'
  }, {
    id: 9,
    name: 'Regeneration',
    src: '../../images/sprites/regeneration.png'
  },
  {
    id: 10,
    name: 'SpellShield',
    src: '../../images/sprites/spellShield.webp'
  }, {
    id: 11,
    name: 'Fated',
    src: '../../images/sprites/fated.webp'
  }, {
    id: 12,
    name: 'Formidable',
    src: '../../images/sprites/formidable.webp'
  }, {
    id: 13,
    name: 'ChampLevelUpIcon2', // levelUp_1
    src: '../../images/sprites/comp.webp'
  }, {
    id: 14,
    name: 'Attune',
    src: '../../images/sprites/attune.webp'
  }, {
    id: 15,
    name: 'Frostbite',
    src: '../../images/sprites/frostbite.png'
  }, {
    id: 16,
    name: 'Reckless', // cantBlock
    src: '../../images/sprites/reckless.png'
  }, {
    id: 17,
    name: 'Fury',
    src: '../../images/sprites/fury.webp'
  }, {
    id: 18,
    name: 'Lurker', //lurk
    src: '../../images/sprites/lurker.png'
  }, {
    id: 19,
    name: 'Impact', // impact_1
    src: '../../images/sprites/impact.webp'
  }, {
    id: 20,
    name: 'ImpactB', // impact_0
    src: '../../images/sprites/comp.webp'
  }, {
    id: 21,
    name: 'ChampLevelUpIcon1', // levelUp_0
    src: '../../images/sprites/comp.webp'
  }, {
    id: 22,
    name: 'champion', // championCard
    src: '../../images/sprites/champion.webp'
  }, {
    id: 23,
    name: 'Fleeting',
    src: '../../images/sprites/fleeting.png'
  }, {
    id: 24,
    name: 'QuickStrike', // quickAttack
    src: '../../images/sprites/quickStrike.png'
  }, {
    id: 25,
    name: 'Silenced',
    src: '../../images/sprites/silenced.webp'
  }, {
    id: 26,
    name: 'Stunned',
    src: '../../images/sprites/stunned.webp'
  }, {
    id: 27,
    name: 'Tough',
    src: '../../images/sprites/tough.png'
  }, {
    id: 28,
    name: 'Vulnerable',
    src: '../../images/sprites/vulnerable.webp'
  }, {
    id: 29,
    name: 'BandleCity',
    src: '../../images/sprites/icon-bandlecity.png'
  }, {
    id: 30,
    name: 'epic', // epicCard
    src: '../../images/sprites/epic.webp'
  }, {
    id: 31,
    name: 'Fearsome',
    src: '../../images/sprites/fearsome.png'
  }, {
    id: 32,
    name: 'Immobile',
    src: '../../images/sprites/immobile.webp'
  }, {
    id: 33,
    name: 'LastBreath',
    src: '../../images/sprites/lastBreath.png'
  }, {
    id: 34,
    name: 'Lifesteal', // lifeSteal
    src: '../../images/sprites/lifesteal.png'
  }, {
    id: 35,
    name: 'Overwhelm',
    src: '../../images/sprites/overwhelm.png'
  }, {
    id: 36,
    name: 'SkillMark', // skill_1
    src: '../../images/sprites/comp.webp'
  }, {
    id: 37,
    name: 'skill_0', // name unknown
    src: '../../images/sprites/comp.webp'
  }, {
    id: 38,
    name: 'Shurima',
    src: '../../images/sprites/icon-shurima.png'
  }, {
    id: 39,
    name: 'rare', // rareCard
    src: '../../images/sprites/rare.webp'
  }, {
    id: 40,
    name: 'Capture',
    src: '../../images/sprites/capture.webp'
  }, {
    id: 41,
    name: 'Challenger',
    src: '../../images/sprites/challenger.webp'
  }, {
    id: 42,
    name: 'Deep',
    src: '../../images/sprites/deep.webp'
  }, {
    id: 43,
    name: 'DoubleStrike', // doubleAttack
    src: '../../images/sprites/doubleStrike.png'
  }, {
    id: 44,
    name: 'Elusive',
    src: '../../images/sprites/elusive.png'
  }, {
    id: 45,
    name: 'Ephemeral',
    src: '../../images/sprites/ephemeral.png'
  }, {
    id: 46,
    name: 'Targon',
    src: '../../images/sprites/icon-targon.png'
  }, {
    id: 47,
    name: 'Fast',
    src: '../../images/sprites/comp.webp'
  }, {
    id: 48,
    name: 'Slow',
    src: '../../images/sprites/comp.webp'
  }, {
    id: 49,
    name: 'Focus',
    src: '../../images/sprites/comp.webp'
  }, {
    id: 50,
    name: 'landmark', // name unknown
    src: '../../images/sprites/comp.webp'
  }, {
    id: 51,
    name: 'currency_bp', // coin
    src: '../../images/sprites/currency_bp.webp'
  }, {
    id: 52,
    name: 'currency_shards', // shard
    src: '../../images/sprites/currency_shards.webp'
  }, {
    id: 53,
    name: 'common', // commonCard
    src: '../../images/sprites/common.webp'
  }, {
    id: 54,
    name: 'allRegion', // name unknown
    src: '../../images/sprites/icon-all.png'
  }, {
    id: 55,
    name: 'BilgeWater',
    src: '../../images/sprites/icon-bilgewater.png'
  }, {
    id: 56,
    name: 'Demacia',
    src: '../../images/sprites/icon-demacia.png'
  }, {
    id: 57,
    name: 'Freljord',
    src: '../../images/sprites/icon-freljord.png'
  }, {
    id: 58,
    name: 'Ionia',
    src: '../../images/sprites/icon-ionia.png'
  }, {
    id: 59,
    name: 'Noxus',
    src: '../../images/sprites/icon-noxus.png'
  }, {
    id: 60,
    name: 'PiltoverZaun', // pnz
    src: '../../images/sprites/icon-piltoverzaun.png'
  }, {
    id: 61,
    name: 'ShadowIsles',
    src: '../../images/sprites/icon-shadowisles.png'
  }
];


// export const spriteList = [
//   {
//     id: 0,
//     name: 'burst',
//     src: '../../images/sprites/burst.svg'
//   }, {
//     id: 1,
//     name: 'barrier',
//     src: '../../images/sprites/barrier.webp'
//   }, {
//     id: 2,
//     name: 'spell',
//     src: '../../images/sprites/spell.svg'
//   }, {
//     id: 3,
//     name: 'scout',
//     src: '../../images/sprites/scout.webp'
//   }, {
//     id: 4,
//     name: 'augmented',
//     src: '../../images/sprites/augmented.webp'
//   }, {
//     id: 5,
//     name: 'attach',
//     src: '../../images/sprites/attach.webp'
//   }, {
//     id: 6,
//     name: 'levelUp_2',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 7,
//     name: 'aura',
//     src: '../../images/sprites/aura.webp'
//   }, {
//     id: 8,
//     name: 'effect',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 9,
//     name: 'regeneration',
//     src: '../../images/sprites/regeneration.webp'
//   },
//   {
//     id: 10,
//     name: 'spellShield',
//     src: '../../images/sprites/spellShield.webp'
//   }, {
//     id: 11,
//     name: 'fated',
//     src: '../../images/sprites/fated.webp'
//   }, {
//     id: 12,
//     name: 'formidable',
//     src: '../../images/sprites/formidable.webp'
//   }, {
//     id: 13,
//     name: 'levelUp_1',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 14,
//     name: 'attune',
//     src: '../../images/sprites/attune.webp'
//   }, {
//     id: 15,
//     name: 'frostbite',
//     src: '../../images/sprites/frostbite.webp'
//   }, {
//     id: 16,
//     name: 'cantBlock',
//     src: '../../images/sprites/cantBlock.webp'
//   }, {
//     id: 17,
//     name: 'fury',
//     src: '../../images/sprites/fury.webp'
//   }, {
//     id: 18,
//     name: 'lurk',
//     src: '../../images/sprites/lurk.webp'
//   }, {
//     id: 19,
//     name: 'impact_1',
//     src: '../../images/sprites/impact_1.webp'
//   }, {
//     id: 20,
//     name: 'impact_0',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 21,
//     name: 'levelUp_0',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 22,
//     name: 'championCard',
//     src: '../../images/sprites/championWildcard.webp'
//   }, {
//     id: 23,
//     name: 'fleeting',
//     src: '../../images/sprites/fleeting.webp'
//   }, {
//     id: 24,
//     name: 'quickAttack',
//     src: '../../images/sprites/quickAttack.webp'
//   }, {
//     id: 25,
//     name: 'silenced',
//     src: '../../images/sprites/silenced.webp'
//   }, {
//     id: 26,
//     name: 'stunned',
//     src: '../../images/sprites/stunned.webp'
//   }, {
//     id: 27,
//     name: 'tough',
//     src: '../../images/sprites/tough.webp'
//   }, {
//     id: 28,
//     name: 'vulnerable',
//     src: '../../images/sprites/vulnerable.webp'
//   }, {
//     id: 29,
//     name: 'bandleCity',
//     src: '../../images/sprites/icon-bandlecity.png'
//   }, {
//     id: 30,
//     name: 'epicCard',
//     src: '../../images/sprites/epicWildCard.webp'
//   }, {
//     id: 31,
//     name: 'fearsome',
//     src: '../../images/sprites/fearsome.webp'
//   }, {
//     id: 32,
//     name: 'immobile',
//     src: '../../images/sprites/cantAttack.webp'
//   }, {
//     id: 33,
//     name: 'lastBreath',
//     src: '../../images/sprites/lastBreath.webp'
//   }, {
//     id: 34,
//     name: 'lifeSteal',
//     src: '../../images/sprites/lifeSteal.webp'
//   }, {
//     id: 35,
//     name: 'overwhelm',
//     src: '../../images/sprites/overwhelm.webp'
//   }, {
//     id: 36,
//     name: 'skill_1',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 37,
//     name: 'skill_0',
//     src: '../../images/sprites/comp.webp'
//   }, {
//     id: 38,
//     name: 'shurima',
//     src: '../../images/sprites/icon-shurima.png'
//   }, {
//     id: 39,
//     name: 'rareCard',
//     src: '../../images/sprites/rareWildCard.webp'
//   }, {
//     id: 40,
//     name: 'capture',
//     src: '../../images/sprites/capture.webp'
//   }, {
//     id: 41,
//     name: 'challenger',
//     src: '../../images/sprites/challenger.webp'
//   }, {
//     id: 42,
//     name: 'deep',
//     src: '../../images/sprites/deep.webp'
//   }, {
//     id: 43,
//     name: 'doubleAttack',
//     src: '../../images/sprites/doubleAttack.webp'
//   }, {
//     id: 44,
//     name: 'elusive',
//     src: '../../images/sprites/elusive.webp'
//   }, {
//     id: 45,
//     name: 'ephemeral',
//     src: '../../images/sprites/ephemeral.webp'
//   }, {
//     id: 46,
//     name: 'targon',
//     src: '../../images/sprites/icon-targon.png'
//   }, {
//     id: 47,
//     name: 'fast',
//     src: '../../images/sprites/fast.svg'
//   }, {
//     id: 48,
//     name: 'slow',
//     src: '../../images/sprites/slow.svg'
//   }, {
//     id: 49,
//     name: 'focus',
//     src: '../../images/sprites/focus.svg'
//   }, {
//     id: 50,
//     name: 'landmark',
//     src: '../../images/sprites/landmark.svg'
//   }, {
//     id: 51,
//     name: 'coin',
//     src: '../../images/sprites/coin.webp'
//   }, {
//     id: 52,
//     name: 'shard',
//     src: '../../images/sprites/shard.webp'
//   }, {
//     id: 53,
//     name: 'commonCard',
//     src: '../../images/sprites/commonWildCard.webp'
//   }, {
//     id: 54,
//     name: 'allRegion',
//     src: '../../images/sprites/icon-all.png'
//   }, {
//     id: 55,
//     name: 'bildgeWater',
//     src: '../../images/sprites/icon-bildgewater.png'
//   }, {
//     id: 56,
//     name: 'demacia',
//     src: '../../images/sprites/icon-demacia.png'
//   }, {
//     id: 57,
//     name: 'frejord',
//     src: '../../images/sprites/icon-frejord.png'
//   }, {
//     id: 58,
//     name: 'ionia',
//     src: '../../images/sprites/icon-ionia.png'
//   }, {
//     id: 59,
//     name: 'noxus',
//     src: '../../images/sprites/icon-noxus.png'
//   }, {
//     id: 60,
//     name: 'pnz',
//     src: '../../images/sprites/icon-piltoverzaun.png'
//   }, {
//     id: 61,
//     name: 'shaddowIsles',
//     src: '../../images/sprites/icon-shaddowisles.png'
//   }
// ];
